export const INITIAL_VALUE = {
  myProducts: [],
  allProducts: [],
};

export const GET_MY_PRODUCTS = 'GET_MY_PRODUCTS';
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';


export const ELPOWER_PAID_SLUG = 'elpower-paid';
export const ELPOWER_FFREE_SLUG = 'elpower-free';

export const PRODUCTS_NAME_BY_SLUG = {
  'elpower-paid': 'Services.ElPower_PaidVersion',
  'elpower-free': 'Services.ElPower_FreeVersion'
}