import React from 'react';
import './Burger.scss'
import {connect} from "react-redux";
import {switchIsShowSideBarMenu} from '../../actions/controlPanel-actions';

const Burger = props => {
  const {isShowSideBarMenu, switchIsShowSideBarMenu} = props;


  const burger = (
    <>
      <span className='burger' />
      <span className='burger second-burger' />
      <span className='burger' />
    </>
  );

  const cross = (
    <>
      <span className='cross' />
      <span className='cross' />
    </>
  );

  const dataElement = isShowSideBarMenu ?
    {
      icon : cross,
      isShowClass: 'showed'
    } :
    {
      icon: burger,
      isShowClass: 'hidden'
    };

  const {icon, isShowClass} = dataElement;
  return (
    <div className={`burger-container ${isShowClass}`} onClick={switchIsShowSideBarMenu}>
      {icon}
    </div>
  )
};

const mapStateToProps = state => {
  const {ControlPanel} = state;
  return {
    isShowSideBarMenu: ControlPanel.isShowSideBarMenu,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    switchIsShowSideBarMenu: () => dispatch(switchIsShowSideBarMenu())
  }
};



export default connect(mapStateToProps, mapDispatchToProps)(Burger)

