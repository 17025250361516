import {
  CONTROL_PANEL_COMMON_INFORMATION,
  CONTROL_PANEL_ACCOUNTS,
  CONTROL_PANEL_COMMON_SERVICES,
  CONTROL_PANEL_KEYS
} from '../constants/routes-constants';

export const isAdminLocation = location => {
  if(!location && typeof location !== 'string') return false;
  const adminLocation = 'control-panel';
  return location.includes(adminLocation);
};

export const identifyActiveMenuItem = location => {

  if(CONTROL_PANEL_COMMON_INFORMATION === location) return 0;

  if(CONTROL_PANEL_KEYS === location) return 1;

  if(CONTROL_PANEL_COMMON_SERVICES === location) return 2;

  if(CONTROL_PANEL_ACCOUNTS === location) return 3;
  const regexAccountsId = /\/control-panel\/accounts\/\S+/;
  const execAccountsId = regexAccountsId.exec(location);
  if(execAccountsId) return 3;

  return 2;
};