import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { devToolsEnhancer } from "redux-devtools-extension/logOnlyInProduction";
import rootReducer from '../reducers';

const initialState = {};
const enhancers = [devToolsEnhancer()];
const middleware = [thunk];
const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);
const store = createStore(rootReducer, initialState, composedEnhancers);
export { store };
