import {
    APPLICATION_SET_CURRENT_USER,
    APPLICATION_CLEAR_CURRENT_USER,
    APPLICATION_SET_CURRENT_USER_ROLE,
    APPLICATION_CLEAR_CURRENT_USER_ROLE,
    APPLICATION_RESET,
    APPLICATION_SET_KEYS,
    APPLICATION_CLEAR_KEYS,
    APPLICATION_SET_SESSIONS,
    APPLICATION_CLEAR_SESSIONS,
} from "../types";


const INITIAL_STATE = {
    currentUser: null,
    currentUserRole: null,
    keys: [],
    sessions: [],
    maxSessions: 3,
    emailTemplates: [],
    managementEnabled: false,
    canManage: false
};

export default function ApplicationReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case APPLICATION_SET_CURRENT_USER: {
            const currentUser = action.payload;
            const { role } = currentUser;
            return {
                ...state, currentUser,
                managementEnabled: ['moderator', 'manager', 'admin'].includes(role),
            };
        }
        case APPLICATION_CLEAR_CURRENT_USER:
            return {
                ...state, currentUser: null
            };
        case APPLICATION_SET_CURRENT_USER_ROLE:
            return {
                ...state, currentUserRole: action.payload
            };
        case APPLICATION_CLEAR_CURRENT_USER_ROLE:
            return {
                ...state, currentUserRole: null
            };
        case APPLICATION_SET_KEYS:
            return {
                ...state, keys: action.payload
            };
        case APPLICATION_CLEAR_KEYS:
            return {
                ...state, keys: []
            };
        case APPLICATION_SET_SESSIONS:
            return {
                ...state, sessions: action.payload
            };
        case APPLICATION_CLEAR_SESSIONS:
            return {
                ...state, sessions: []
            };
        case APPLICATION_RESET:
            return {
                ...state, ...INITIAL_STATE
            };
        default:
            return state;
    }
}
