import './polyfills'
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import './assets/base.scss';
import App from './App';
import { Provider } from 'react-redux';
import { store } from "./storage";
import "./i18n";
import "./utils/api"; // This import should be keep here to avoid logout on page reload


const rootElement = document.getElementById('root');
const renderApp = Component => {
    ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <Component />
            </BrowserRouter>
        </Provider>,
        rootElement
    );
};

renderApp(App);

if (module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default;
        renderApp(NextApp);
    });
}
serviceWorker.unregister();
