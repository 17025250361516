import React from 'react';
import './ThreeDotsMenuStyles.scss';

const ThreeDotsMenu = props => {
  const {onClickHandler, isActive} = props;

  const isDotsActive = isActive ? 'dots-active' : 'dots-inactive';

  return (
    <div className='tree-dots-wrapper'>
      <div className={`three-dots-menu-container ${isDotsActive}`} onClick={onClickHandler}>
        <span className='one-dot' />
        <span className='one-dot second-dont' />
        <span className='one-dot' />
      </div>
    </div>

  );
};

export default ThreeDotsMenu;
