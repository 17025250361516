import {
  APPLICATION_SET_CURRENT_USER,
  APPLICATION_CLEAR_CURRENT_USER,
  APPLICATION_SET_CURRENT_USER_ROLE,
  APPLICATION_CLEAR_CURRENT_USER_ROLE,
  APPLICATION_SET_KEYS,
  APPLICATION_CLEAR_KEYS,
  APPLICATION_SET_SESSIONS,
  APPLICATION_CLEAR_SESSIONS,
  APPLICATION_RESET
} from "../types";
import { HttpClient } from "../utils/api/index";
import { logOut, setBlockedUser } from "./auth";

export function fetchCurrentUser(isLogOut = true) {
  return async function (dispatch) {
    try {
      const user = await HttpClient.get('/users/my', {}, {ignoreAuthentication: true});
      dispatch(setCurrentUser(user));
    } catch (e) {
      if (isLogOut) {
        dispatch(logOut());
      }
    }
  }
}

export function fetchCurrentUserRole() {
  return async function (dispatch) {
    try {
      const role = await HttpClient.get('/roles/my');
      dispatch(setCurrentUserRole(role));
    } catch (e) {
      dispatch(logOut());
    }
  }
}

export function fetchKeys() {
  return async function (dispatch) {
    try {
      const { applicationKeys } = await HttpClient.get('/application-keys/my');
      dispatch(setKeys(applicationKeys));
    } catch (e) {
      dispatch(clearKeys());
    }
  }
}

export function fetchSessions() {
  return async function (dispatch) {
    try {
      const { applicationSessions } = await HttpClient.get('/application-sessions/my');
      dispatch(setSessions(applicationSessions));
    } catch (e) {
      dispatch(clearSessions());
    }
  }
}

export function deleteSession(sessionId) {
  return async function (dispatch) {
    try {
      await HttpClient.delete(`/application-sessions/${sessionId}`);
      dispatch(fetchSessions());
    } catch (e) {
      dispatch(clearSessions());
    }
  }
}

function setCurrentUser(user) {
  return { type: APPLICATION_SET_CURRENT_USER, payload: user };
}

export function clearCurrentUser() {
  return { type: APPLICATION_CLEAR_CURRENT_USER };
}

function setCurrentUserRole(role) {
  return { type: APPLICATION_SET_CURRENT_USER_ROLE, payload: role };
}

export function clearCurrentUserRole() {
  return { type: APPLICATION_CLEAR_CURRENT_USER_ROLE };
}

export function setKeys(keys) {
  return { type: APPLICATION_SET_KEYS, payload: keys };
}

export function clearKeys() {
  return { type: APPLICATION_CLEAR_KEYS };
}

export function setSessions(sessions) {
  return { type: APPLICATION_SET_SESSIONS, payload: sessions };
}

export function clearSessions() {
  return { type: APPLICATION_CLEAR_SESSIONS };
}

export function applicationReset() {
  return { type: APPLICATION_RESET };
}
