import {
    ACCOUNTS_SET_LIST_ITEMS,
    ACCOUNTS_CLEAR_LIST_ITEMS,
    ACCOUNTS_SET_PAGE,
    ACCOUNTS_SET_PAGES,
    ACCOUNTS_SET_PAGE_SIZE,
    ACCOUNTS_INCREMENT_PROGRESS,
    ACCOUNTS_DECREMENT_PROGRESS,
    ACCOUNTS_SET_ERRORS,
    ACCOUNTS_CLEAR_ERRORS,
    ACCOUNTS_SET_ACCOUNT_ID,
    ACCOUNTS_SET_ACCOUNT,
    ACCOUNTS_CLEAR_ACCOUNT,
    ACCOUNTS_SHOW_PASSWORD_CHANGE_CONFIRMATION,
    ACCOUNTS_HIDE_PASSWORD_CHANGE_CONFIRMATION,
    ACCOUNTS_SHOW_DETAILS_CHANGE_CONFIRMATION,
    ACCOUNTS_HIDE_DETAILS_CHANGE_CONFIRMATION,
    ACCOUNTS_RESET
} from "../types";


const INITIAL_STATE = {
    listItems: [],
    page: 0,
    pages: 1,
    pageSize: 20,
    listProgress: 0,
    leftBarProgress: 0,
    detailsProgress: 0,
    stateProgress: 0,
    actionsProgress: 0,
    leftBarErrors: {},
    detailsErrors: {},
    accountId: null,
    account: null,
    passwordChangeConfirmation: false,
    detailsChangeConfirmation: false
};

export default function AccountsReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case ACCOUNTS_SET_LIST_ITEMS:
            return {
                ...state, listItems: action.payload
            };
        case ACCOUNTS_CLEAR_LIST_ITEMS:
            return {
                ...state, listItems: []
            };
        case ACCOUNTS_SET_PAGE:
            return {
                ...state, page: action.payload
            };
        case ACCOUNTS_SET_PAGES:
            return {
                ...state, pages: action.payload
            };
        case ACCOUNTS_SET_PAGE_SIZE:
            return {
                ...state, pageSize: action.payload, page: 0
            };
        case ACCOUNTS_INCREMENT_PROGRESS:
            return {
                ...state, [action.payload]: state[action.payload] + 1
            };
        case ACCOUNTS_DECREMENT_PROGRESS:
            return {
                ...state, [action.payload]: state[action.payload] > 0 ? state[action.payload] - 1 : 0
            };
        case ACCOUNTS_SET_ERRORS: {
            const { type, errors } = action.payload;
            return {
                ...state, [type]: errors
            };
        }
        case ACCOUNTS_CLEAR_ERRORS:
            return {
                ...state, [action.payload]: {}
            };
        case ACCOUNTS_SET_ACCOUNT_ID:
            return {
                ...state, accountId: action.payload
            };
        case ACCOUNTS_SET_ACCOUNT:
            return {
                ...state, account: action.payload
            };
        case ACCOUNTS_CLEAR_ACCOUNT:
            return {
                ...state, account: null
            };
        case ACCOUNTS_SHOW_PASSWORD_CHANGE_CONFIRMATION:
            return {
                ...state, passwordChangeConfirmation: true
            };
        case ACCOUNTS_HIDE_PASSWORD_CHANGE_CONFIRMATION:
            return {
                ...state, passwordChangeConfirmation: false
            };
        case ACCOUNTS_SHOW_DETAILS_CHANGE_CONFIRMATION:
            return {
                ...state, detailsChangeConfirmation: true
            };
        case ACCOUNTS_HIDE_DETAILS_CHANGE_CONFIRMATION:
            return {
                ...state, detailsChangeConfirmation: false
            };
        case ACCOUNTS_RESET:
            return {
                ...INITIAL_STATE
            };
        default:
            return state;
    }
}
