import {
  CHANGE_SITE_LANGUAGE
} from '../constants/language-constants';

export const changeLanguageAction = lng => {
  return async dispatch => {
    dispatch({
      type: CHANGE_SITE_LANGUAGE,
      payload: {
        lng
      }
    })
  };
};