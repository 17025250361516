import {
    EMAILS_SET_LIST,
    EMAILS_CLEAR_LIST
} from "../types";


const INITIAL_STATE = {
    emailsList: []
};

export default function EmailsReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case EMAILS_SET_LIST:
            return {
                ...state, emailsList: action.payload
            };
        case EMAILS_CLEAR_LIST:
            return {
                ...state, emailsList: []
            };
        default:
            return state;
    }
}
