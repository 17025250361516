import {
    AUTH_INCREMENT_PROGRESS,
    AUTH_DECREMENT_PROGRESS,
    AUTH_RESET_PROGRESS,
    AUTH_SET_ERRORS,
    AUTH_CLEAR_ERRORS,
    AUTH_SHOW_RECOVERY_EMAIL_SENT,
    AUTH_HIDE_RECOVERY_EMAIL_SENT,
    AUTH_SHOW_PASSWORD_RECOVERED,
    AUTH_HIDE_PASSWORD_RECOVERED,
    AUTH_SET_BLOCKED_USER,
    AUTH_UNSET_BLOCKED_USER,
} from "../constants/auth-constants";
import { HttpClient } from "../utils/api/index";
import { clearAccessToken, setAccessToken, updateConfirmationSentEmailTime } from "./persistStorage";
import { applicationReset } from "./application";
import { accountsReset } from "./accounts";

export const setBlockedUser = () => {
    return {
        type: AUTH_SET_BLOCKED_USER
    }
};

export const unsetBlockedUser = () => {
    return {
        type: AUTH_UNSET_BLOCKED_USER
    }
};

export const checkAuthorizationTokenPing = () => {
  return async dispatch => {
      dispatch(incrementProgress());
      try {
          const {status} = await HttpClient.postPingAuth();
          if(status !== 200) logOut();
      } catch(e) {
          dispatch(setErrors(e.errors));
      } finally  {
          dispatch(decrementProgress());
      }
  }
};

export function login(email, password) {
    return async function (dispatch) {
        dispatch(clearErrors());
        dispatch(incrementProgress());
        try {
            const response = await HttpClient.post('/auth/email/login', { email, password });
            const { token } = response;
            if (token) dispatch(setAccessToken(token));
        } catch (e) {
            if(e.status === 403) dispatch(setBlockedUser());
            dispatch(setErrors(e.errors));
        } finally {
            dispatch(decrementProgress());
        }
    }
}

export function register(email, password, password_confirmation) {
    return async function (dispatch) {
        dispatch(clearErrors());
        dispatch(incrementProgress());
        try {
            const response = await HttpClient.post('/auth/email/register', { email, password, password_confirmation });
            const { token } = response;
            if (token) {
                dispatch(setAccessToken(token));
                dispatch(updateConfirmationSentEmailTime());
            }
        } catch (e) {
            dispatch(setErrors(e.errors));
        } finally {
            dispatch(decrementProgress());
        }
    }
}

export function requestRecovery(email) {
    return async function (dispatch) {
        dispatch(clearErrors());
        dispatch(incrementProgress());
        try {
            await HttpClient.post('/auth/password/requestRecovery', { email });
            dispatch(showRecoveryEmailSent());
        } catch (e) {
            dispatch(setErrors(e.errors));
        } finally {
            dispatch(decrementProgress());
        }
    }
}

export function confirmPasswordRecovery(token, onSuccess, onFail) {
    return async function (dispatch) {
        try {
            const { token: recoveryToken } = await HttpClient.post('/auth/password/confirmRecovery', { token });
            onSuccess(recoveryToken);
        } catch (e) {
            onFail(e);
        }
    }
}

export function recoverPassword(token, password, password_confirmation) {
    return async function (dispatch) {
        dispatch(clearErrors());
        dispatch(incrementProgress());
        try {
            await HttpClient.post('/auth/password/recover', { token, password, password_confirmation });
            dispatch(showPasswordRecovered());
        } catch (e) {
            dispatch(setErrors(e.errors));
        } finally {
            dispatch(decrementProgress());
        }
    }
}

export function confirmEmail(token, onSuccess, onFail) {
    return async function (dispatch) {
        try {
            await HttpClient.post('/auth/email/confirm', { token });
            onSuccess();
        } catch (e) {
            onFail(e);
        }
    }
}

export function requestEmailConfirmation(onSuccess, onFail) {
    return async function (dispatch) {
        try {
            await HttpClient.post('/auth/email/requestConfirmation', { });
            onSuccess();
        } catch (e) {
            onFail(e);
        }
    }
}

export function logOut() {
    return function (dispatch) {
        dispatch(clearAccessToken());
        dispatch(applicationReset());
        dispatch(accountsReset());
    }
}

function incrementProgress() {
    return { type: AUTH_INCREMENT_PROGRESS };
}

function decrementProgress() {
    return { type: AUTH_DECREMENT_PROGRESS };
}

export function resetProgress() {
    return { type: AUTH_RESET_PROGRESS };
}

function setErrors(errors) {
    return { type: AUTH_SET_ERRORS, payload: errors };
}

export function clearErrors() {
    return { type: AUTH_CLEAR_ERRORS };
}

function showRecoveryEmailSent() {
    return { type: AUTH_SHOW_RECOVERY_EMAIL_SENT };
}

export function hideRecoveryEmailSent() {
    return { type: AUTH_HIDE_RECOVERY_EMAIL_SENT };
}

function showPasswordRecovered() {
    return { type: AUTH_SHOW_PASSWORD_RECOVERED };
}

export function hidePasswordRecovered() {
    return { type: AUTH_HIDE_PASSWORD_RECOVERED };
}
