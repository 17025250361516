import {
  SWITCH_SHOWING_SIDE_BAR_MENU,
  TURN_OFF_SIDE_BUR_MENU,
  SELECT_MENU_ITEM,
  TURN_ON_SIDE_BUR_MENU,
} from '../constants/control-panel-constants';
import {HttpClient} from "../utils/api";

export const buyServiceOrder = (productId, daysNumber, redirectLink) => {
  return async dispatch => {
    try {
      const body = {
        'productsList': [
          {
            'id': productId,
            'amount': daysNumber
          }
        ],
        'redirectUrl': redirectLink
      }
      const orderCreatingResult = await HttpClient.post('/orders', body);
      const {link} = orderCreatingResult;
      // window.open(link, '_blank').focus();
      window.location.href = link;
    } catch (e) {
      console.log('create order error', e);
    }
  }
}

export const switchIsShowSideBarMenu = () => {
  return {
    type: SWITCH_SHOWING_SIDE_BAR_MENU,
    payload: null,
  }
};

export const turnOffSideBarMenu = () => {
  return {
    type: TURN_OFF_SIDE_BUR_MENU,
    payload: null,
  }
};

export const turnOnSideBarMenu = () => {
  return {
    type: TURN_ON_SIDE_BUR_MENU,
    payload: null,
  }
};

export const selectMenuItem = id => {
  return {
    type: SELECT_MENU_ITEM,
    payload: {id},
  }
};