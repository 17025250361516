import { combineReducers } from "redux";
import ThemeOptions from './ThemeOptions';
import AuthReducer from "./Auth";
import ApplicationReducer from "./Application";
import PersistStorageReducer from "./PersistStorage";
import EmailsReducer from "./Emails";
import AccountsReducer from "./Accounts";
import LanguageReducer from './Language-reducer';
import ControlPanelReducer from './ControlPanelReducer'
import productsReducer from './products-reducer';


export default combineReducers({
    Auth: AuthReducer,
    Accounts: AccountsReducer,
    Application: ApplicationReducer,
    Emails: EmailsReducer,
    PersistStorage: PersistStorageReducer,
    ThemeOptions,
    Language:LanguageReducer,
    ControlPanel: ControlPanelReducer,
    Products: productsReducer,
})