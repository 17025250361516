import React, {Suspense} from 'react';
import HeaderController from './components/Header/HeaderController';
import Routes from './components/Routes/Routes';
import FooterController from './components/Footer/FooterController';
import './App.scss';
import FullScreenSpinnerLoading from "./components/FullScreenSpinnerLoading";

// TODO modal window



const App = () => {

  return (
    <div className="app-container">
      <Suspense fallback={<FullScreenSpinnerLoading/>}>
        <HeaderController />
        <Routes />
        <FooterController />
      </Suspense>
    </div>
  )
};

export default App;