import React from 'react';
import FooterClient from './FooterClient/FooterClient';
import FooterAdmin from './FooterAdmin/FooterAdmin';
import {useLocation} from 'react-router-dom';
import {isAdminLocation} from "../../helpers/router-helper";

const FooterController = () => {

  const Footer = isAdminLocation(useLocation().pathname) ? FooterAdmin : FooterClient;
  return(
    <Footer />
  );
};

export default FooterController;