import React from 'react';
import './FooterAdminStyles.scss';
import Copyright from "../../../components/Copyright";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram, faYoutube, faCcMastercard, faCcVisa} from "@fortawesome/free-brands-svg-icons";
import {faComments, faEnvelope} from "@fortawesome/free-regular-svg-icons";
import {faGlobeAmericas} from "@fortawesome/free-solid-svg-icons";
import liqPay from '../../../assets/images/logo-liqpay-main.svg';

import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Link} from "react-router-dom";


const FooterAdmin = props => {
    const {currentLanguage, t} = props;
    const getSocialLink = socialLinkName => {
        switch (socialLinkName) {
            case 'youtubeLink':
                return currentLanguage === 'en' ?
                    'https://www.youtube.com/AutoscopeTechnology/playlists' :
                    'https://www.youtube.com/InjectorService/playlists';
            case 'siteLink':
                return currentLanguage === 'en' ?
                    'https://usbautoscope.eu/' : 'https://injectorservice.com.ua/';
            default:
                return null;
        }
    };
    const youtubeLink = getSocialLink('youtubeLink');
    const siteLink = getSocialLink('siteLink');
    return (
        <footer className = 'footer-common footer-admin'>

            <div className='wrap_phone'>
                {/*<a href='' className='phone'>(+38)067-700-56-65</a>*/}
                {/*<FontAwesomeIcon icon={faPhoenixSquadron}/>*/}
            </div>

            <div className='wrap_secondary'>
                <span className='policy-container'>
                     <Link to='/policy'>{t('policy')}</Link>
                 </span>
                <Copyright className="text-secondary"/>
            </div>


            <div className='wrap_social-elements'>

                <div className='social-elements'>
                    <div className='social-elements-pack'>
                        <a href="https://www.liqpay.ua/" target="_blank" title="LiqPay"
                           rel="noopener noreferrer" className='pay-logo'>
                            <img src={liqPay} alt="liqpay" className='liq-pay-img'/>
                        </a>
                    </div>
                    <div className='social-elements-pack'>
                        <a href="https://www.visa.com" target="_blank" title="Visa"
                           rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faCcVisa}/>
                        </a>
                        <a href="https://www.mastercard.com" target="_blank" title="MasterCard"
                           rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faCcMastercard}/>
                        </a>
                    </div>
                    <div className='social-elements-pack'>
                        <a href="https://www.facebook.com/usbautoscope/" target="_blank" title="Facebook"
                           rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebookF}/>
                        </a>
                        <a href="https://www.instagram.com/usbautoscope/" target="_blank" title="Instagram"
                           rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram}/>
                        </a>
                        <a href={youtubeLink} target="_blank" title="YouTube" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faYoutube}/>
                        </a>
                    </div>
                    <div className='social-elements-pack'>
                        <a href="https://forum.injectorservice.com.ua/" target="_blank" title={t('Forum')}
                           rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faComments}/>
                        </a>
                        <a href={siteLink} target="_blank" title={t('Site')} rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faGlobeAmericas}/>
                        </a>
                        <a href="mailto:support@waveform-analyzer.com" target="_blank" title={t('e-mail')}
                           rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faEnvelope}/>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
};
const mapStateToProps = state => {
     const {Language} = state;
    return {
        currentLanguage: Language.currentLanguage
    }
};
export default withTranslation('footer')(connect(mapStateToProps)(FooterAdmin));
