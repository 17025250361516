import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';
import {storageInitState} from '../../actions/routes-actions';
import {checkAuthorizationTokenPing} from '../../actions/auth';
import './RoutesStyles.scss';

const MainPage = lazy(() => import('../Main/Main'));
const PolicyPage = lazy(() => import('../Policy/Policy'));
const AboutUsPage = lazy(() => import('../AboutUs/AboutUs'));
const ContractPage = lazy(() => import('../Contract/Contract'));
const AuthPage = lazy(() => import('../Auth/Auth'));
const HandlePage = lazy(() => import('../Handle/Handle'));
const BlockedPage = lazy(() => import('../Blocked/Blocked'));
const ControlPanel = lazy(() => import('../ControlPanel/ControlPanel'));
const Downloads = lazy(() => import('../Downloads/Downloads'));

class Routes extends Component {

  componentDidMount() {
    const {storageInitState, checkAuthorizationTokenPing} = this.props;
    storageInitState();
    checkAuthorizationTokenPing();
  }

  render() {
    const {authenticated} = this.props;
    return (
      <section className='routes-container'>
        <Route exact path="/" render={() => (<Redirect to="/home"/>)}/>

        <Route path="/home" component={MainPage}/>

        <Route path="/policy" component={PolicyPage}/>

        <Route path="/about" component={AboutUsPage}/>

        <Route path="/contract" component={ContractPage}/>

        <Route path="/auth" component={AuthPage}/>

        <Route path="/handle" component={HandlePage}/>

        <Route path="/downloads" component={Downloads}/>

        <Route exact path="/blocked" component={BlockedPage}/>

        <Route path="/control-panel" render={(props) => {
            return authenticated ? <ControlPanel {...props} /> : <Redirect to="/auth" />
        }}/>
        {/*<ToastContainer/>*/}
      </section>
    )
  }
}

const mapStateToProps = ({ PersistStorage }) => ({
  authenticated: PersistStorage.authenticated,
});

const mapDispatchToProps = {
    storageInitState,
    checkAuthorizationTokenPing
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
