import React, { useState, useEffect } from "react";
import './HeaderAdminStyles.scss';
import { connect } from 'react-redux';
import LanguageSwitcher from '../../LanguageSwitcher/LanguageSwitcher';
import HeaderLogo from '../HeaderLogo/HeaderLogo';
import UserBox from './UserBox/UserBox';
import Burger from '../../Burger/Burger';
import ThreeDotsMenu from '../ThreeDotsMenu/ThreeDotsMenu';

const HeaderAdmin = props => {
  const [isShowUserContainerTabAndMobile, setIsShowUserContainerTabAndMobile] = useState(false);
  useEffect(() => {

  }, [isShowUserContainerTabAndMobile]);

  const threeDotsClickHandler = () =>  {
    setIsShowUserContainerTabAndMobile(!isShowUserContainerTabAndMobile);
  };


  return (
    <header className="header-common header-admin">
      <div className='header-admin-wrapper'>
        <Burger />
        <HeaderLogo />
        <div className={`user-container ${isShowUserContainerTabAndMobile ? 'active-user-block-tab-and-mobile' : ''}`}>
          <LanguageSwitcher place={'controlPanel'} />
          <UserBox />
        </div>
        <ThreeDotsMenu onClickHandler={threeDotsClickHandler} isActive={isShowUserContainerTabAndMobile} />
      </div>
    </header>
  )
};

const mapStateToProps = state => {
  const {Application, ControlPanel} = state;
  return {
    currentUser: Application.currentUser,
    isShowSideBarMenu: ControlPanel.isShowSideBarMenu,
  }
};

const mapDispatchToProps = dispatch => {
  return {

  }
};



export default connect(mapStateToProps, mapDispatchToProps)(HeaderAdmin);
