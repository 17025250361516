import {
    ACCOUNTS_INCREMENT_PROGRESS,
    ACCOUNTS_DECREMENT_PROGRESS,
    ACCOUNTS_SET_PAGES,
    ACCOUNTS_SET_LIST_ITEMS,
    ACCOUNTS_CLEAR_LIST_ITEMS,
    ACCOUNTS_SET_PAGE,
    ACCOUNTS_SET_PAGE_SIZE,
    ACCOUNTS_SET_ACCOUNT_ID,
    ACCOUNTS_SET_ACCOUNT,
    ACCOUNTS_CLEAR_ACCOUNT,
    ACCOUNTS_RESET,
    ACCOUNTS_SET_ERRORS,
    ACCOUNTS_CLEAR_ERRORS,
    ACCOUNTS_SHOW_PASSWORD_CHANGE_CONFIRMATION,
    ACCOUNTS_HIDE_PASSWORD_CHANGE_CONFIRMATION,
    ACCOUNTS_SHOW_DETAILS_CHANGE_CONFIRMATION,
    ACCOUNTS_HIDE_DETAILS_CHANGE_CONFIRMATION
} from "../types";
import { HttpClient } from "../utils/api/index";
import { store } from "../storage";
import {CHANGE_SITE_LANGUAGE} from "../constants/language-constants";


export function fetchAccounts() {
    return async function(dispatch) {
        dispatch(incrementProgress('listProgress'));
        try {
            const { page, pageSize } = store.getState().Accounts;
            const { users, pages } = await HttpClient.get(`/users?page=${page+1}&pageSize=${pageSize}`);
            dispatch(setPages(pages));
            if (page >= pages - 1) {
                dispatch(setPage(pages - 1));
            }
            dispatch(setListItems(users));
        } catch (e) {
            dispatch(clearListItems());
        } finally {
            dispatch(decrementProgress('listProgress'));
        }
    }
}

export function fetchAccount(publicId, success, fail) {
    return async function(dispatch) {
        try {
            dispatch(incrementAccountProgress());
            dispatch(setAccountId(publicId));
            const user = await HttpClient.get(`/users/${publicId}`);
            const { accountId } = store.getState().Accounts;
            if (publicId === accountId) {
                dispatch(setAccount(user));
            }
            success();
        } catch (e) {
            fail(e);
        } finally {
            dispatch(decrementAccountProgress());
        }
    }
}

export function setAccountPassword(publicId, password, password_confirmation, success, fail) {
    return async function(dispatch) {
        try {
            dispatch(clearErrors('leftBarErrors'));
            dispatch(incrementProgress('leftBarProgress'));
            await HttpClient.post(`/users/${publicId}/password`, { password, password_confirmation });
            dispatch(showPasswordChangeConfirmation());
            success();
        } catch (e) {
            dispatch(setErrors('leftBarErrors', e.errors));
            fail();
        } finally {
            dispatch(decrementProgress('leftBarProgress'));
        }
    }
}

export function patchAccount(publicId, data, success, fail) {
    return async function(dispatch) {
        try {
            dispatch(clearErrors('detailsErrors'));
            dispatch(incrementProgress('detailsProgress'));
            await HttpClient.patch(`/users/${publicId}`, data);
            dispatch(showDetailsChangeConfirmation());
            success();
        } catch (e) {
            dispatch(setErrors('detailsErrors', e.errors));
            fail();
        } finally {
            dispatch(decrementProgress('detailsProgress'));
        }
    }
}

export function changeUserLanguageAction(lng, data) {
    return async dispatch => {
        try {
            dispatch(clearErrors('detailsErrors'));
            dispatch(incrementProgress('detailsProgress'));
            await HttpClient.patch(`/users/my`, data);
            dispatch({
                type: CHANGE_SITE_LANGUAGE,
                payload: {
                    lng
                }
            })

        } catch (e) {
            dispatch(setErrors('detailsErrors', e.errors));
        } finally {
            dispatch(decrementProgress('detailsProgress'));
        }
    }
}

export function setAccountBlockedState(publicId, blocked, success, fail) {
    return async function(dispatch) {
        try {
            dispatch(incrementProgress('stateProgress'));
            await HttpClient[blocked ? 'post' : 'delete'](`/users/${publicId}/block`, { });
            success();
        } catch (e) {
            fail();
        } finally {
            dispatch(decrementProgress('stateProgress'));
        }
    }
}

export function setAccountConfirmedState(publicId, confirmed, success, fail) {
    return async function(dispatch) {
        try {
            dispatch(incrementProgress('stateProgress'));
            await HttpClient[confirmed ? 'post' : 'delete'](`/users/${publicId}/confirm`, { });
            success();
        } catch (e) {
            fail();
        } finally {
            dispatch(decrementProgress('stateProgress'));
        }
    }
}

export function setAccountRole(publicId, role, success, fail) {
    return async function(dispatch) {
        try {
            dispatch(incrementProgress('stateProgress'));
            await HttpClient.post(`/users/${publicId}/role`, { role });
            success();
        } catch (e) {
            fail();
        } finally {
            dispatch(decrementProgress('stateProgress'));
        }
    }
}

export function hardDeleteAccount(publicId, success, fail) {
    return async function(dispatch) {
        try {
            dispatch(incrementProgress('actionsProgress'));
            await HttpClient.delete(`/users/${publicId}/hard`);
            success();
        } catch (e) {
            fail();
        } finally {
            dispatch(decrementProgress('actionsProgress'));
        }
    }
}

export function clearAccountSessions(publicId, success, fail) {
    return async function(dispatch) {
        try {
            dispatch(incrementProgress('actionsProgress'));
            await HttpClient.post(`/sessions/${publicId}/delete`, { });
            success();
        } catch (e) {
            fail();
        } finally {
            dispatch(decrementProgress('actionsProgress'));
        }
    }
}

export function renewAccountKeys(publicId, success, fail) {
    return async function(dispatch) {
        try {
            dispatch(incrementProgress('actionsProgress'));
            await HttpClient.post(`/application-keys/${publicId}/renew`, { });
            success();
        } catch (e) {
            fail();
        } finally {
            dispatch(decrementProgress('actionsProgress'));
        }
    }
}

export function setPage(page) {
    return { type: ACCOUNTS_SET_PAGE, payload: page };
}

export function setPageSize(pageSize) {
    return { type: ACCOUNTS_SET_PAGE_SIZE, payload: pageSize };
}

export function accountsReset() {
    return { type: ACCOUNTS_RESET };
}

function incrementProgress(type) {
    return { type: ACCOUNTS_INCREMENT_PROGRESS, payload: type };
}

function decrementProgress(type) {
    return { type: ACCOUNTS_DECREMENT_PROGRESS, payload: type  };
}

function setErrors(type, errors) {
    return { type: ACCOUNTS_SET_ERRORS, payload: { type, errors } };
}

function clearErrors(type) {
    return { type: ACCOUNTS_CLEAR_ERRORS, payload: type };
}

export function clearAllErrors() {
    return function(dispatch) {
        dispatch(clearErrors('leftBarErrors'));
        dispatch(clearErrors('detailsErrors'));
    }
}

function incrementAccountProgress() {
    return function(dispatch) {
        dispatch(incrementProgress('leftBarProgress'));
        dispatch(incrementProgress('detailsProgress'));
        dispatch(incrementProgress('stateProgress'));
        dispatch(incrementProgress('actionsProgress'));
    }
}

function decrementAccountProgress() {
    return function(dispatch) {
        dispatch(decrementProgress('leftBarProgress'));
        dispatch(decrementProgress('detailsProgress'));
        dispatch(decrementProgress('stateProgress'));
        dispatch(decrementProgress('actionsProgress'));
    }
}

function setPages(pages) {
    return { type: ACCOUNTS_SET_PAGES, payload: pages };
}

function setListItems(users) {
    return { type: ACCOUNTS_SET_LIST_ITEMS, payload: users };
}

function clearListItems() {
    return { type: ACCOUNTS_CLEAR_LIST_ITEMS };
}

function setAccountId(publicId) {
    return { type: ACCOUNTS_SET_ACCOUNT_ID, payload: publicId };
}

function setAccount(user) {
    return { type: ACCOUNTS_SET_ACCOUNT, payload: user };
}

function showPasswordChangeConfirmation() {
    return { type: ACCOUNTS_SHOW_PASSWORD_CHANGE_CONFIRMATION };
}

export function hidePasswordChangeConfirmation() {
    return { type: ACCOUNTS_HIDE_PASSWORD_CHANGE_CONFIRMATION };
}

function showDetailsChangeConfirmation() {
    return { type: ACCOUNTS_SHOW_DETAILS_CHANGE_CONFIRMATION };
}

export function hideDetailsChangeConfirmation() {
    return { type: ACCOUNTS_HIDE_DETAILS_CHANGE_CONFIRMATION };
}

export function clearAccount() {
    return { type: ACCOUNTS_CLEAR_ACCOUNT };
}
