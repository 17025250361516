export const AUTH_INCREMENT_PROGRESS = 'AUTH_INCREMENT_PROGRESS';
export const AUTH_DECREMENT_PROGRESS = 'AUTH_DECREMENT_PROGRESS';
export const AUTH_RESET_PROGRESS = 'AUTH_DECREMENT_PROGRESS';
export const AUTH_SET_ERRORS = 'AUTH_SET_ERRORS';
export const AUTH_CLEAR_ERRORS = 'AUTH_CLEAR_ERRORS';
export const AUTH_SHOW_RECOVERY_EMAIL_SENT = 'AUTH_SHOW_RECOVERY_EMAIL_SENT';
export const AUTH_HIDE_RECOVERY_EMAIL_SENT = 'AUTH_HIDE_RECOVERY_EMAIL_SENT';
export const AUTH_SHOW_PASSWORD_RECOVERED = 'AUTH_SHOW_PASSWORD_RECOVERED';
export const AUTH_HIDE_PASSWORD_RECOVERED = 'AUTH_HIDE_PASSWORD_RECOVERED';
export const AUTH_SET_BLOCKED_USER = 'AUTH_SET_BLOCKED_USER';
export const AUTH_UNSET_BLOCKED_USER = 'AUTH_UNSET_BLOCKED_USER';

export const INITIAL_STATE = {
  token: '',
  progress: 0,
  recoveryEmailModalShown: false,
  passwordRecoveredModalShown: false,
  isAuthenticated: false,
  errors: { },
  isBlocked: false
};