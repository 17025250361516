import axios from "axios";


export const Axios = axios.create({
    baseURL: "https://waveform-analyzer.com/api",
    // baseURL: "https://waveform-analyzer.com/api",
    // baseURL: "http://192.168.1.156:5000/api",
    responseType: "json",
    headers: {'Content-Type': 'application/json'}
});


