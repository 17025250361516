export const APPLICATION_SET_CURRENT_USER = 'APPLICATION_SET_CURRENT_USER';
export const APPLICATION_CLEAR_CURRENT_USER = 'APPLICATION_CLEAR_CURRENT_USER';
export const APPLICATION_SET_CURRENT_USER_ROLE = 'APPLICATION_SET_CURRENT_USER_ROLE';
export const APPLICATION_CLEAR_CURRENT_USER_ROLE = 'APPLICATION_CLEAR_CURRENT_USER_ROLE';
export const APPLICATION_SET_KEYS = 'APPLICATION_SET_KEYS';
export const APPLICATION_CLEAR_KEYS = 'APPLICATION_CLEAR_KEYS';
export const APPLICATION_SET_SESSIONS = 'APPLICATION_SET_SESSIONS';
export const APPLICATION_CLEAR_SESSIONS = 'APPLICATION_CLEAR_SESSIONS';
export const APPLICATION_RESET = 'APPLICATION_RESET';

export const EMAILS_SET_LIST = 'EMAILS_SET_LIST';
export const EMAILS_CLEAR_LIST = 'EMAILS_CLEAR_LIST';

export const ACCOUNTS_SET_LIST_ITEMS = 'ACCOUNTS_SET_LIST_ITEMS';
export const ACCOUNTS_CLEAR_LIST_ITEMS = 'ACCOUNTS_CLEAR_LIST_ITEMS';
export const ACCOUNTS_SET_PAGE = 'ACCOUNTS_SET_PAGE';
export const ACCOUNTS_SET_PAGES = 'ACCOUNTS_SET_PAGES';
export const ACCOUNTS_SET_PAGE_SIZE = 'ACCOUNTS_SET_PAGE_SIZE';
export const ACCOUNTS_INCREMENT_PROGRESS = 'ACCOUNTS_INCREMENT_PROGRESS';
export const ACCOUNTS_DECREMENT_PROGRESS = 'ACCOUNTS_DECREMENT_PROGRESS';
export const ACCOUNTS_SET_ERRORS = 'ACCOUNTS_SET_ERRORS';
export const ACCOUNTS_CLEAR_ERRORS = 'ACCOUNTS_CLEAR_ERRORS';
export const ACCOUNTS_SET_ACCOUNT_ID = 'ACCOUNTS_SET_ACCOUNT_ID';
export const ACCOUNTS_SET_ACCOUNT = 'ACCOUNTS_SET_ACCOUNT';
export const ACCOUNTS_CLEAR_ACCOUNT = 'ACCOUNTS_CLEAR_ACCOUNT';
export const ACCOUNTS_SHOW_PASSWORD_CHANGE_CONFIRMATION = 'ACCOUNTS_SHOW_PASSWORD_CHANGE_CONFIRMATION';
export const ACCOUNTS_HIDE_PASSWORD_CHANGE_CONFIRMATION = 'ACCOUNTS_HIDE_PASSWORD_CHANGE_CONFIRMATION';
export const ACCOUNTS_SHOW_DETAILS_CHANGE_CONFIRMATION = 'ACCOUNTS_SHOW_DETAILS_CHANGE_CONFIRMATION';
export const ACCOUNTS_HIDE_DETAILS_CHANGE_CONFIRMATION = 'ACCOUNTS_HIDE_DETAILS_CHANGE_CONFIRMATION';
export const ACCOUNTS_RESET = 'ACCOUNTS_RESET';
