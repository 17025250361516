export const languagesList = {
  en: 'en',
  ru: 'ru',
  ua: 'ua',
};

export const CHANGE_SITE_LANGUAGE = 'CHANGE_SITE_LANGUAGE';

export const INITIAL_STATE = {
  currentLanguage: '',
};

