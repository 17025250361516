import {
  STORAGE_INIT_STATE,
} from "../constants/persistStorage-constants";


export const storageInitState = () => {
  return dispatch => {
    dispatch({
      type: STORAGE_INIT_STATE,
    })
  }
};