import {
    INITIAL_STATE,
    AUTH_INCREMENT_PROGRESS,
    AUTH_DECREMENT_PROGRESS,
    AUTH_RESET_PROGRESS,
    AUTH_SET_ERRORS,
    AUTH_CLEAR_ERRORS,
    AUTH_SHOW_RECOVERY_EMAIL_SENT,
    AUTH_HIDE_RECOVERY_EMAIL_SENT,
    AUTH_SHOW_PASSWORD_RECOVERED,
    AUTH_HIDE_PASSWORD_RECOVERED,
    AUTH_SET_BLOCKED_USER,
    AUTH_UNSET_BLOCKED_USER,
} from '../constants/auth-constants'

export default function AuthReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AUTH_INCREMENT_PROGRESS:
            return {
                ...state, progress: state.progress + 1
            };
        case AUTH_DECREMENT_PROGRESS:
            return {
                ...state, progress: state.progress > 0 ? state.progress - 1 : 0
            };
        case AUTH_RESET_PROGRESS:
            return {
                ...state, progress: 0
            };
        case AUTH_SET_ERRORS:
            return {
                ...state, errors: action.payload
            };
        case AUTH_CLEAR_ERRORS:
            return {
                ...state, errors: { }
            };
        case AUTH_SHOW_RECOVERY_EMAIL_SENT:
            return {
                ...state, recoveryEmailModalShown: true
            };
        case AUTH_HIDE_RECOVERY_EMAIL_SENT:
            return {
                ...state, recoveryEmailModalShown: false
            };
        case AUTH_SHOW_PASSWORD_RECOVERED:
            return {
                ...state, passwordRecoveredModalShown: true
            };
        case AUTH_HIDE_PASSWORD_RECOVERED:
            return {
                ...state, passwordRecoveredModalShown: false
            };
        case AUTH_SET_BLOCKED_USER:
            return {
                ...state, isBlocked: true
            };
        case AUTH_UNSET_BLOCKED_USER:
            return {
                ...state, isBlocked: false
            };
        default:
            return state;
    }
}
