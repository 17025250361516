import React from 'react';
import {
    DropdownToggle, DropdownMenu,
    Nav, Button, NavItem, NavLink,
    UncontrolledButtonDropdown
} from 'reactstrap';
import { faAngleDown, faUserCircle, faCog, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import city3 from '../../../../assets/utils/images/dropdown-header/city3.jpg';
import { connect } from "react-redux";
import { logOut } from "../../../../actions/auth";
import { withTranslation } from "react-i18next";


class UserBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
        };
    }

    __onLogout = () => {
        this.props.logOut();
    };

    render() {
        const { currentUser, t } = this.props;
        return (
            <div className="header-btn-lg pr-0">
                <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                        <div className="widget-content-left mr-3 header-user-info">
                            <div className="widget-heading">
                                { currentUser && currentUser.email }
                            </div>
                            <div className="widget-subheading">
                                { currentUser && currentUser.role && currentUser.role !== 'user' && t(`roles:${currentUser.role}`) }
                            </div>
                        </div>
                        <div className="widget-content-left">
                            <UncontrolledButtonDropdown>
                                <DropdownToggle color="link" className="p-0">
                                    <FontAwesomeIcon style={{verticalAlign: 'middle', width: '44px', height: '44px'}} icon={faUserCircle}/>
                                    <FontAwesomeIcon className="ml-2 opacity-8" icon={faAngleDown}/>
                                </DropdownToggle>
                                <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                                    <div className="dropdown-menu-header">
                                        <div className="dropdown-menu-header-inner bg-info">
                                            <div className="menu-header-image opacity-2"
                                                 style={{
                                                     backgroundImage: 'url(' + city3 + ')'
                                                 }}
                                            />
                                            <div className="menu-header-content text-left">
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-wrapper">
                                                        <div className="widget-content-left mr-3">
                                                            {/*<img width={42} className="rounded-circle" src={avatar1} alt=""/>*/}
                                                        </div>
                                                        <div className="widget-content-left">
                                                            <div className="widget-heading">
                                                                {currentUser && currentUser.email}
                                                            </div>
                                                        </div>
                                                        <div className="widget-content-right mr-2">
                                                            <Button className="btn-pill btn-shadow btn-shine" color="focus" onClick={this.__onLogout}>
                                                                {t('Layout.Logout')}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Nav vertical>
                                        <NavItem className="nav-item-header">
                                            {t('Layout.MyAccount')}
                                        </NavItem>
                                        <NavItem>
                                            <NavLink href="#" className="disabled">
                                                <FontAwesomeIcon className="opacity-8 mr-2" icon={faCog}/>
                                                {t('Layout.AccountSettings')}
                                            </NavLink>
                                            {/*<NavItem>*/}
                                            {/*    <NavLink href="#">*/}
                                            {/*        <FontAwesomeIcon className="opacity-8 mr-2" icon={faKey}/>*/}
                                            {/*        Recover password*/}
                                            {/*    </NavLink>*/}
                                            {/*</NavItem>*/}
                                        </NavItem>
                                        <NavItem className="nav-item-divider mb-2"/>
                                        <NavItem>
                                            <NavLink href="mailto:support@waveform-analyzer.com" target="_blank">
                                                <FontAwesomeIcon className="opacity-8 mr-2" icon={faEnvelope}/>
                                                support@waveform-analyzer.com
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ Application }) => ({
    currentUser: Application.currentUser
});

const mapDispatchToProps = dispatch => ({
    logOut() {
        dispatch(logOut());
    }
});

export default withTranslation(['control-panel', 'roles'])(connect(mapStateToProps, mapDispatchToProps)(UserBox));
