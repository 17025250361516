import {
  INITIAL_VALUE,
  GET_MY_PRODUCTS,
  GET_ALL_PRODUCTS
} from '../constants/products-constants';
import {APPLICATION_RESET} from "../types";

const productsReducer = (state = INITIAL_VALUE, action) => {
  const {type, payload} = action;
  switch(type) {
    case GET_MY_PRODUCTS:
      return {...state, myProducts: payload};
    case GET_ALL_PRODUCTS:
      return {...state, allProducts: payload};
    case APPLICATION_RESET:
      return {...INITIAL_VALUE};
    default:
      return state;
  }
};

export default productsReducer;
