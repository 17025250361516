

export const storageSetAccessToken = payload => {
  const lsPersist = JSON.parse(localStorage.getItem('persist'));
  const setTokenPersistStorage = {
    ...lsPersist,
    token: payload, authenticated: !!payload
  };
  localStorage.setItem('persist', JSON.stringify(setTokenPersistStorage));
};

export const storageClearAccessToken = () => {
  const lsPersist = JSON.parse(localStorage.getItem('persist'));
  const clearTokenPersistStorage = {
    ...lsPersist,
    token: '', authenticated: false
  };
  localStorage.setItem('persist', JSON.stringify(clearTokenPersistStorage));
};

export const storageUpdateConfirmationEmailSent = () => {
  const lsPersist = JSON.parse(localStorage.getItem('persist'));
  const updateConfirmationPersistStorage = {
    ...lsPersist,
    confirmationSentTime: Date.now()
  };
  localStorage.setItem('persist', JSON.stringify(updateConfirmationPersistStorage));
};

export const storageGetToken = () => {
  const lsPersist = JSON.parse(localStorage.getItem('persist'));
  return lsPersist.token;
};

export const storageGetLanguage = () => {
  const lng = localStorage.getItem('lng');
  if(lng.includes('en')) return 'en';
  else if(lng.includes('ru')) return 'ru';
  else if(lng.includes('ua')) return 'ua';
  else return 'en';
};
