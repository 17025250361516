import {CHANGE_SITE_LANGUAGE, INITIAL_STATE} from "../constants/language-constants";

const LanguageReducer = (state = INITIAL_STATE, action) => {
  const {type, payload} = action;
  switch (type) {
    case CHANGE_SITE_LANGUAGE:
      return {...state, currentLanguage: payload.lng};
    default: return state;
  }
};

export default LanguageReducer;
