import React from "react";
import { connect } from 'react-redux'
import { withTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import './FooterClientStyles.scss';
import '../FooterCommonStyles.scss'
import {
  faCcMastercard,
  faCcVisa,
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faComments, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faGlobeAmericas } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const FooterClient = props => {
  const { currentLanguage, t } = props;
  const getSocialLink = socialLinkName => {
    switch (socialLinkName) {
      case 'youtubeLink':
        return currentLanguage === 'en' ?
        'https://www.youtube.com/AutoscopeTechnology/playlists' :
        'https://www.youtube.com/InjectorService/playlists';
      case 'siteLink':
        return currentLanguage === 'en' ?
          'https://usbautoscope.eu/' : 'https://injectorservice.com.ua/';
      default: return null;
    }
  };

  const youtubeLink = getSocialLink('youtubeLink');
  const siteLink = getSocialLink('siteLink');

  return (
    <footer className='footer-common footer-client'>
      <div className='left-bar'>
        <div className='contacts'>
          <a className='link' href="tel:+380974942328" rel="noopener noreferrer">
            <i className="lnr-phone-handset" /><span>+380(97)4942328</span>
          </a>
          <a className='link' href="tel:+380636396740" rel="noopener noreferrer">
            <i className="lnr-phone-handset" /><span>+380(63)6396740</span>
          </a>
          <a className='link' href="mailto:support@waveform-analyzer.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faEnvelope}/>
            <span>support@waveform-analyzer.com</span>
          </a>
        </div>
      </div>

      <span className='policy-container'>
        <Link to='/about'>{t('aboutUs')}</Link>
      </span>

      <span className='policy-container'>
        <Link to='/policy'>{t('policy')}</Link>
      </span>

      <span className='policy-container'>
        <a href="/docs/Public_Offer_Agreement.pdf" target="_blank" rel="noopener noreferrer">{t('contract')}</a>
      </span>

      <div className='social-elements'>
        <div className='social-elements-pack'>
          <a href="https://www.visa.com" target="_blank" title="Visa"
             rel="noopener noreferrer">
            <FontAwesomeIcon icon={faCcVisa}/>
          </a>
          <a href="https://www.mastercard.com" target="_blank" title="MasterCard"
             rel="noopener noreferrer">
            <FontAwesomeIcon icon={faCcMastercard}/>
          </a>
        </div>

        <div className='social-elements-pack'>
          <a href="https://www.facebook.com/usbautoscope/" target="_blank" title="Facebook" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebookF}/>
          </a>
          <a href="https://www.instagram.com/usbautoscope/" target="_blank" title="Instagram" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram}/>
          </a>
          <a href={youtubeLink} target="_blank" title="YouTube" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube}/>
          </a>
        </div>

        <div className='social-elements-pack'>
          <a href="https://forum.injectorservice.com.ua/" target="_blank" title={t('Forum')} rel="noopener noreferrer">
            <FontAwesomeIcon icon={faComments}/>
          </a>
          <a href={siteLink} target="_blank" title={t('Site')} rel="noopener noreferrer">
            <FontAwesomeIcon icon={faGlobeAmericas}/>
          </a>
          <a href="mailto:support@waveform-analyzer.com" target="_blank" title={t('e-mail')} rel="noopener noreferrer">
            <FontAwesomeIcon icon={faEnvelope}/>
          </a>
        </div>
      </div>
    </footer>
  )
};

const mapStateToProps = state => {
  const {Language} = state;
  return {
    currentLanguage: Language.currentLanguage
  }
};

const mapDispatchToProps = dispatch => {
  return {

  }
};

export default withTranslation('footer')(connect(mapStateToProps, mapDispatchToProps)(FooterClient));
