export const INITIAL_STATE = {
  isShowSideBarMenu: false,
  activeMenuLinkIndex: 1,
};

export const SWITCH_SHOWING_SIDE_BAR_MENU = 'SWITCH_SHOWING_SIDE_BAR_MENU';
export const TURN_OFF_SIDE_BUR_MENU = 'TURN_OFF_SIDE_BUR_MENU';
export const TURN_ON_SIDE_BUR_MENU = 'TURN_ON_SIDE_BUR_MENU';
export const SELECT_MENU_ITEM = 'SELECT_MENU_ITEM';

export const OPEN_BUY_SERVICE_MODAL = 'OPEN_BUY_SERVICE_MODAL';
export const CLOSE_BUY_SERVICE_MODAL = 'CLOSE_BUY_SERVICE_MODAL';
