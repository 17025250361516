import {
  INITIAL_STATE,
  SWITCH_SHOWING_SIDE_BAR_MENU,
  TURN_OFF_SIDE_BUR_MENU,
  SELECT_MENU_ITEM,
  TURN_ON_SIDE_BUR_MENU
} from '../constants/control-panel-constants';

const ControlPanelReducer = (state = INITIAL_STATE, action) => {
  const {type, payload} = action;
  switch (type) {
    case SWITCH_SHOWING_SIDE_BAR_MENU: return {...state, isShowSideBarMenu: !state.isShowSideBarMenu};

    case TURN_OFF_SIDE_BUR_MENU: return {...state, isShowSideBarMenu: false};

    case TURN_ON_SIDE_BUR_MENU: return {...state, isShowSideBarMenu: true};

    case SELECT_MENU_ITEM: return {...state, activeMenuLinkIndex: payload.id};
    default: return state;
  }
};

export default ControlPanelReducer;