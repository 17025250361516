import React, {useEffect} from 'react';
import { connect} from "react-redux";
import { useHistory } from "react-router-dom";
import './LanguageSwitcherStyles.scss';
import {languagesList} from '../../constants/language-constants';
import {storageGetToken, storageGetLanguage} from '../../helpers/localStorage-helper'
import {changeLanguageAction} from "../../actions/languageSwitcher-actions";
import {changeUserLanguageAction} from "../../actions/accounts";

const LanguageSwitcherComponent = props => {
  const {place, changeLanguage, changeUserLanguage, Language} = props;
  const {currentLanguage} = Language;
  const {en, ru, ua} = languagesList;

  useEffect(() => {
    if(!currentLanguage) setLanguage(storageGetLanguage());
  });

  const isAuth = !!storageGetToken();

  const setLanguage = lng => {
    if(isAuth) changeUserLanguage(lng);
    else changeLanguage(lng)
  };

  const activeLanguage = lng => {
    return lng === currentLanguage ? 'active-lng-btn' : '';
  };

  return (
    <div className={`${place}-lng-switch-container`}>
      <button
        className={`lng-btn ${activeLanguage(en)}`}
        onClick={() => setLanguage(en)}
      >EN</button>
      <span className='lng-slash'>|</span>
      <button
        className={`lng-btn ${activeLanguage(ua)}`}
        onClick={() => setLanguage(ua)}
      >UA</button>
      <span className='lng-slash'>|</span>
      <button
        className={`lng-btn ${activeLanguage(ru)}`}
        onClick={() => setLanguage(ru)}
      >RU</button>
    </div>
  );
};

const mapStateToProps = ({ Language }) => ({
  Language,
});

const mapDispatchToProps = dispatch => ({
  changeLanguage(lng) {
    dispatch(changeLanguageAction(lng))
  },
  changeUserLanguage(lng) {
    dispatch(changeUserLanguageAction(lng, {locale: lng}))
  }

});

const LanguageSwitcher = connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcherComponent);

export default LanguageSwitcher;
