import React from 'react';
import HeaderClient from './HeaderClient/HeaderClient';
import HeaderAdmin from './HeaderAdmin/HeaderAdmin';
import './HeaderCommonStyles.scss';
import {useLocation} from 'react-router-dom';
import {isAdminLocation} from "../../helpers/router-helper";


const HeaderController = () => {
  const Header = isAdminLocation(useLocation().pathname) ? HeaderAdmin : HeaderClient;
  return <Header />
};

export default HeaderController;