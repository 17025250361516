import React from "react";
import './HeaderClientStyles.scss';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import LanguageSwitcher from '../../LanguageSwitcher/LanguageSwitcher';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faSignInAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom'
import HeaderLogo from '../HeaderLogo/HeaderLogo';

const HeaderClient = props => {
  const {currentUser} = props;


  const icon = currentUser ? faUser : faSignInAlt;
  return (
    <header className="header-common header-client">
      <HeaderLogo />
      <div className='icons-wrapper'>
        <LanguageSwitcher place={'client'} />
        <Link className='icon-auth-user' to={'/auth'}>
          <FontAwesomeIcon color={'#52A4A4'} className='icon' icon={icon} />
        </Link>
      </div>
    </header>
  )
};

const mapStateToProps = state => {
  const {Application} = state;
  return {
    currentUser: Application.currentUser,
  }
};

const mapDispatchToProps = dispatch => {
  return {

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderClient);
