import {
  STORAGE_INIT_STATE,
  STORAGE_SET_ACCESS_TOKEN,
  STORAGE_CLEAR_ACCESS_TOKEN,
  STORAGE_UPDATE_CONFIRMATION_EMAIL_SENT_TIME,
  INITIAL_STATE
} from "../constants/persistStorage-constants";

import {
  storageSetAccessToken,
  storageClearAccessToken,
  storageUpdateConfirmationEmailSent,
} from '../helpers/localStorage-helper';

export default function PersistStorageReducer(state = INITIAL_STATE, action) {
  const {type, payload} = action;
  switch (type) {

    case STORAGE_INIT_STATE:
      return {...state};

    case STORAGE_SET_ACCESS_TOKEN: // login action
      storageSetAccessToken(payload);
      return {
          ...state, token: action.payload, authenticated: !!action.payload
      };
    case STORAGE_CLEAR_ACCESS_TOKEN: // log out action
      storageClearAccessToken();
      return {
          ...state, token: '', authenticated: false // TODO Do we need clear confirmationSentTime here????
      };
    case STORAGE_UPDATE_CONFIRMATION_EMAIL_SENT_TIME: // create account action
      storageUpdateConfirmationEmailSent();
      return {
          ...state, confirmationSentTime: Date.now()
      };
    default:
        return state;
  }
}
