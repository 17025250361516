import React from 'react';
import Spinner from "../Spinner";


export default function FullScreenSpinnerLoading() {
    return <div className="loader-container">
        <div className="loader-container-inner">
            <div className="text-center">
                <Spinner/>
            </div>
        </div>
    </div>
}
