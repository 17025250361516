import { Axios } from "./axios";
import { store } from "../../storage";
import { logOut } from "../../actions/auth";
import i18n from "../../i18n";


export class HttpClient {
    static get(endpoint, params = {}, options = {}) {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await Axios.get(endpoint, { params });
                resolve(data);
            } catch (error) {
                reject(handlerError(error, options))
            }
        });
    }

    static postPingAuth() {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await Axios.post('/auth/ping', {}, {});
                resolve(response);
            } catch (error) {
                reject(handlerError({}, {ignoreAuthentication: true}));
            }
        });
    }

    static post(endpoint, body, config = {}, options = {}) {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await Axios.post(endpoint, body, config);
                resolve(data);
            } catch (error) {
                reject(handlerError(error, options))
            }
        });
    }

    static patch(endpoint, body, config = {}, options = {}) {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await Axios.patch(endpoint, body, config);
                resolve(data);
            } catch (error) {
                reject(handlerError(error, options))
            }
        });
    }

    static delete(endpoint, params = {}, options = {}) {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await Axios.delete(endpoint, { params });
                resolve(data);
            } catch (error) {
                reject(handlerError(error, options))
            }
        });
    }
    static updateHeaders
}

function handlerError(error, options = { }) {
    const { ignoreAuthentication } = options;
    const { response } = error;
    if (response) {
        if (response.status === 401 && !ignoreAuthentication) {
            store.dispatch(logOut());
        }
        return { ...response.data, status: response.status };
    } else {
        return { errors: {}, status: 0 };
    }
}

let currentToken = '';
store.subscribe(() => {
    const { token } = store.getState().PersistStorage;
    if (token !== currentToken) {
        if (!token) {
            delete Axios.defaults.headers.common['Authorization'];
        } else {
            Axios.defaults.headers.common['Authorization'] = token;
        }
        currentToken = token;
    }
});

i18n.on('loaded', () => {
    Axios.defaults.headers.common['Accept-Language'] = i18n.language;
});

i18n.on('languageChanged', (lng) => {
    Axios.defaults.headers.common['Accept-Language'] = lng;
});
