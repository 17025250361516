import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { store } from "./storage";
import moment from "moment";
import "moment/min/locales.min";


i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .use(Backend)
    .init({
        detection: {
            order: ['querystring', 'localStorage', 'navigator'],
            lookupQuerystring: 'lng',
            lookupLocalStorage: 'lng'
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json'
        },
        fallbackLng: 'en'

    }).catch(error => console.log('i18n Error', error));

let currentLocale = '';
store.subscribe(async () => {
    const { currentUser } = store.getState().Application;
    const { currentLanguage } = store.getState().Language;

    const locale = currentLanguage || (currentUser && currentUser.locale) || i18n.language;
    if (locale !== currentLocale) {
        currentLocale = locale;
        await i18n.changeLanguage(locale);
        currentLocale = i18n.language;
        moment.locale(i18n.language);
    }
});

export default i18n;
