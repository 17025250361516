export const STORAGE_INIT_STATE = 'STORAGE_INIT_STATE';
export const STORAGE_SET_ACCESS_TOKEN = 'STORAGE_SET_ACCESS_TOKEN';
export const STORAGE_CLEAR_ACCESS_TOKEN = 'STORAGE_CLEAR_ACCESS_TOKEN';
export const STORAGE_UPDATE_CONFIRMATION_EMAIL_SENT_TIME = 'STORAGE_UPDATE_CONFIRMATION_EMAIL_SENT_TIME';


const getInitialState = () => {
  try {
    return JSON.parse(localStorage.persist);
  } catch (e) {
    const INITIAL_STATE = {
      token: '',
      authenticated: false,
      confirmationSentTime: null
    };
    localStorage.setItem('persist', JSON.stringify(INITIAL_STATE));
    return INITIAL_STATE
  }
};

export const INITIAL_STATE = getInitialState();

